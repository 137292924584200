@import 'style/colors';
@import 'style/typography';

$headerHeight: 60px;
$sidebarWidth: 260px;
$sidebarCollapsedWidth: 60px;
$mobile-width: 768px;
$tablet-width: 1024px;
$md-lg-width: 1200px;
$lg-lg-width: 1440px;
$lg-xl-width: 1535px;
$disable: rgba(0, 0, 0, 0.26);
$margin: 4px;
$padding: 4px;
$font-family: 'Montserrat', sans-serif;

// Colors
$text-light: #757575;
$text: $neutral-70;
$error: #eb7373;
$success: #35c955;
$primary: #7122ff;
$white: white;
$warn: #d89b00;
$backgroundColor: #fff;
$lightGray: #bebebe;
$darkGray: #757575;
$hover: #ededed;
$active: #51ca6b;
$inactive: #f99e6b;
