.ac-switch-selector {
  width: 205px;
  position: relative;
  display: flex;
  justify-content: space-between;
  border: 1px solid #cacbd4;
  border-radius: 4px;
}

.ac-switch-selector-button {
  width: 99px;
  height: 40px;
  padding: 10px 12px 9px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 17px;
  border-radius: 0 !important;

  p {
    color: #717188;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-transform: none;
    text-align: center;
  }

  &.active {
    background-color: #e3d3ff;
    text-align: center;

    p {
      color: #6109ff;
    }

    &:hover {
      background-color: #d1bafc;
    }
  }
}
