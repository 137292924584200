@import '../../variables.scss';

#loginModal {
  width: 400px;
  border-radius: 5px;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 30px rgb(113 34 255 / 0.2);
  position: relative;
  z-index: 2;

  p {
    color: $darkGray;
    font-size: 12px;
    margin: unset;
  }

  .header {
    margin-bottom: 20px;

    h1 {
      font-size: 28px;
      margin-bottom: 12px;
    }
  }

  .body {
    .input {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      label {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      input {
        height: 48px;
        line-height: 48px;
        padding: 0 10px;
      }

      &.short {
        margin-bottom: 10px;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a,
      span {
        font-size: 12px !important;
      }

      span {
        color: $primary;
        font-family: 'Montserrat', sans-serif;
        cursor: pointer;
      }
    }

    button {
      width: 100%;
      height: 48px;
    }
  }
}
