@import '../../variables.scss';

h1,
h2,
h3,
p {
  color: $text;
}

.login-alert {
  width: 400px;
  opacity: 0;
  transition: 0.18s all;
  z-index: 100;
  position: absolute;
  top: 102%;

  &.visible {
    opacity: 1;
  }
}

#loginPage {
  height: 100vh;
  width: 100vw;
  background-color: #322566;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;

  .login-form-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    position: relative;
  }

  .loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
  }

  .strokes {
    div {
      border: 3px solid #7122ff;
      border-radius: 3px;
      position: fixed;
      height: 50%;
    }

    div:first-child {
      box-shadow: 0px 0px 10px rgba(113, 34, 255, 0.8);
      width: 50%;
      bottom: 0;
      left: 0;
      border-width: 3px 3px 0 0;
    }

    div:last-child {
      box-shadow: 20px 20px 20px -20px rgb(113 34 255 / 80%);
      border-width: 0 3px 3px 0;
      width: 30%;
      transform: translateX(-50%);
      top: 0;
      right: 0;
    }
  }
}

.logo {
  width: 200px;
}

@media (max-width: $mobile-width) {
  #loginPage {
    background-size: 130vw 100vh;
  }
}
