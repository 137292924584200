@import '../../variables.scss';
@import '../../style/typography';

.order {
  &__section-title {
    @include typo-body-medium-bolder;
    margin: 0;
  }

  &__products-table .MuiDataGrid-root {
    margin-top: 0;
    font-family: 'Montserrat', sans-serif;

    .MuiDataGrid-columnHeader {
      padding: 0;
    }

    .MuiDataGrid-columnHeaderTitle {
      font-size: 16px;
      font-weight: 500 !important;
    }

    .MuiDataGrid-cell {
      padding: 0;
    }

    .MuiDataGrid-row {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
  }

  &__payment-details-table .MuiDataGrid-root {
    margin-top: 0;
    font-family: 'Montserrat', sans-serif;

    .MuiDataGrid-columnHeaders {
      padding: 0;
      border-bottom: none !important;
    }

    .MuiDataGrid-cell {
      padding: 0;
    }

    .MuiDataGrid-row {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
  }

  &__metadata-table .MuiDataGrid-root {
    .MuiDataGrid-cellContent {
      padding: 15px 0;
      min-height: 52px;
      word-break: break-all;
    }
  }

  &-details .MuiTypography-root {
    font-family: 'Montserrat', sans-serif;
  }
}

.header-title {
  @include typo-body-small-bolder;
  color: $text;
  margin-top: 24px;
}

.order-price {
  @include typo-title-large-bolder;
  color: $text;
}

.cell-title {
  font-size: 12px;
  color: $neutral-50;
}

.cell-value {
  font-size: 14px;
  color: $text;
}

.content-wrapper .order-details {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  column-gap: 100px;
}
