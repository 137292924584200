.importCSVModal {
  .file-name {
    font-size: 14px;
    font-weight: 400;
  }

  .file-size {
    color: #717188;
    font-size: 12px;
    font-weight: 500;
  }

  .close-icon {
    fill: #717188;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
  }

  .MuiDialog-paper {
    padding: 16px;

    .dialog-header {
      padding-bottom: 8px;
      margin-bottom: 12px;
      h2 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 18px;
      }
    }

    .MuiDialogContentText-root {
      font-family: 'Montserrat', sans-serif;
      color: black;
      font-weight: 400;
      font-size: 14px;
    }

    .MuiDialogContent-root {
      padding-bottom: 20px;
    }
  }
}
